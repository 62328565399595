import React, { useEffect, useMemo, useState } from "react";
import "tw-elements";
import { getInstructions, getPhotos, quickSetup } from "../core/_requests";
import { RWebShare } from "react-web-share";
import html2canvas from "html2canvas";
import Masonry from "react-masonry-css";

type Props = {
  clientData: any;
  generalSettings: any;
};

const Gallery: React.FC<Props> = ({ clientData, generalSettings }) => {
  const [photos, setPhotos] = useState<any>([]);
  const [image, setImage] = useState<any>();
  const [selectedOption, setSelectedOption] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [slider, setSlider] = useState(false);
  const [menu, setMenu] = useState(false);
  const [qrCode, setQrCode] = useState(false);
  const [pdfPresentPopup, setPdfPresentPopup] = useState(false);
  const [formData, setFormData] = useState({});
  const [pdfPresent, setPdfPresent] = useState("");
  const [labels, setLabels] = useState(clientData.gallery_labels);
  const [dropdown, setDropdown] = useState<any>([]);

  const timeStamp = new Date().getTime();
  const basePath = process.env.PUBLIC_URL;
  const hostName = process.env.REACT_APP_HOST;

  const pathArray = window.location.pathname.split("/");
  const id = pathArray[2];
  
  const reverseArray = () => {
    const reversedArray = [...photos].reverse();
    setPhotos(reversedArray);
  };

  const breakpointColumnsObj = {
    default: 5,
    1100: 4,
    700: 3,
    500: 2,
  };

  useEffect(() => {
    getInstructions(clientData.app_id).then((response) => {
      setFormData(JSON.parse(response.app_q_a));
      setPdfPresent(response.app_instruction_pdf);
    });
  }, []);

  const emailsDropdown = useMemo(() => {
    return Array.from(
      new Set(
        photos
          .map((item: { email: any }) => item.email)
          .filter((email: any) => email !== undefined && email !== "undefined")
      )
    );
  }, [photos]);

  const firstNameDropdown = useMemo(() => {
    return Array.from(
      new Set(
        photos
          .map((item: { username: any }) => item.username)
          .filter(
            (username: any) =>
              username !== undefined &&
              username !== "undefined" &&
              username !== ""
          )
      )
    );
  }, [photos]);


  useEffect(() => {
    if (labels === "emails") {
      setDropdown(emailsDropdown);
    } else if (labels === "display-first-name") {
      setDropdown(firstNameDropdown);
    }
  }, [labels, emailsDropdown, firstNameDropdown]);

  const app_name = clientData.app_name;
  const filterKey = labels === "emails" ? "email" : "username";


  const onButtonClick = () => {
    const pdfUrl = clientData.printshop_pdf_only_front;
    const newWindow = window.open(pdfUrl, "_blank"); // Open PDF in a new tab/window

    // Wait for the new window to load before triggering the print dialog
    if (newWindow) {
      newWindow.focus(); // Focus on the new window
      newWindow.onload = () => {
        newWindow.print(); // Trigger the print dialog
      };
    }
  };

  useEffect(() => {
    getPhotos(clientData.clients_id).then((response) => {
      // Filter photos based on the presence and value of moderation_status
      const filteredPhotos = response.photos.filter((photo: any) => {
        return (
          !photo.moderation?.moderation_status ||
          photo.moderation.moderation_status !== "reject"
        );
      });
      setPhotos(filteredPhotos);
    });
  }, [clientData.clients_id]);

  const screenshot = async (e: any) => {
    var temp = e.target.getAttribute("data-id");
    let content = document.querySelector(`#${temp}`) as HTMLCanvasElement;
    html2canvas(content, {
      logging: true,
      allowTaint: true,
      useCORS: true,
    }).then((canvas: any) => {
      canvas.toBlob((blob: any) => {
        if (blob && blob !== undefined && typeof blob === "object") {
          // Create a temporary anchor element
          const anchor = document.createElement("a");
          // Set href attribute to the data URL of the canvas
          anchor.href = canvas.toDataURL("image/png");
          // Set download attribute to specify the filename
          anchor.download = "screenshot.png";
          // Trigger a click event on the anchor to start the download
          anchor.click();
          // Clean up: remove the temporary anchor element
          anchor.remove();

          return navigator.clipboard.write([
            new window.ClipboardItem({ "image/png": blob }),
          ]);
        }
      });
      setImage(canvas.toDataURL("image/png"));
    });
  };

  const handleChange = (event: any) => {
    setSelectedOption(event.target.value);
    // sortArrayByEmail(event.target.value);
  };

  return (
    <>
      <div className="sticky -top-3 z-[9999] bg-white -ml-3">
        <button
          className="theme-button-background theme-text-color font-bold my-2 mx-2 py-2 px-6 rounded"
          onClick={() => {
            reverseArray();
          }}
        >
          Sort by Date
        </button>
        {labels !== "display-anonymous" && (
          <select
            className="p-2 theme-button-background theme-text-color font-bold rounded"
            id="options"
            value={selectedOption}
            onChange={handleChange}
          >
            <option value="">Show All Images</option>
            {dropdown.map((option: any, index: number) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        )}
      </div>
      {generalSettings?.icon_hamburger && (
        <img
          id="hamburger_menu"
          src={generalSettings?.icon_hamburger}
          height={50}
          width={50}
          alt=""
          className="social-icon-color-trans fixed cursor-pointer right-6 hidden"
          onClick={() => {
            setMenu(!menu);
          }}
        ></img>
      )}
      {menu === true && (
        <>
          <div
            className="fixed inset-0 w-full h-full bg-black opacity-0"
            onClick={() => setMenu(false)}
          ></div>
          <div className="slide-menu w-[90%] sm:w-[55%] lg:w-[35%] mt-[110px]">
            <div className="flex flex-col ml-[80px] max-w-[50%] z-[1]">
              <a
                href={`${hostName}/client/${clientData.id}`}
                rel="noreferrer"
                target="_blank"
                className="mt-10"
              >
                {generalSettings?.icon_quick_setup && (
                  <div
                    className="flex flex-row items-center cursor-pointer  mb-2"
                    onClick={() => {
                      var data = {
                        client_id: clientData.clients_id,
                        quick_setup: "1",
                      };
                      quickSetup(data);
                    }}
                  >
                    <img
                      alt="Setting Icon "
                      src={generalSettings?.icon_quick_setup}
                      height={30}
                      width={30}
                      className="social-icon-color-trans"
                    />
                    <p className="font-semibold pl-2">Quick Actions</p>
                  </div>
                )}
              </a>
              <RWebShare
                data={{
                  text: "Share",
                  url: `${hostName}/app/${clientData.App_URL_clientname}`,
                }}
              >
                {generalSettings?.icon_share_app && (
                  <div className="flex flex-row  items-center cursor-pointer">
                    <img
                      src={generalSettings?.icon_share_app}
                      alt="share"
                      className="social-icon-color-trans  mb-2 cursor-pointer"
                      height={30}
                      width={30}
                    ></img>
                    <p className="font-semibold pl-2">Share App</p>
                  </div>
                )}
              </RWebShare>
              <div className="flex flex-row items-center cursor-pointer">
                <img
                  src={`${basePath}/images/icons/copy-svgrepo-com.svg`}
                  height={30}
                  width={30}
                  alt=""
                  className="social-icon-color-trans mb-2 "
                  onClick={() =>
                    navigator.clipboard.writeText(
                      `https://zaags.com/app/${clientData.App_URL_clientname}`
                    )
                  }
                ></img>
                <p
                  className="font-semibold pl-2 cursor-pointer"
                  onClick={() =>
                    navigator.clipboard.writeText(
                      `https://zaags.com/app/${clientData.App_URL_clientname}`
                    )
                  }
                >
                  Copy Link
                </p>
              </div>

              {(navigator.userAgent.match(/Android/i) ||
                navigator.userAgent.match(/webOS/i) ||
                navigator.userAgent.match(/iPhone/i) ||
                navigator.userAgent.match(/iPad/i) ||
                navigator.userAgent.match(/iPod/i) ||
                navigator.userAgent.match(/BlackBerry/i) ||
                navigator.userAgent.match(/Windows Phone/i)) &&
                generalSettings?.["icon-add-home"] && (
                  <div
                    className="flex flex-row items-center cursor-pointer "
                    onClick={() => document.getElementById("add-to")?.click()}
                  >
                    <img
                      src={generalSettings?.["icon-add-home"]}
                      height={30}
                      width={30}
                      alt=""
                      className="mb-2 cursor-pointer social-icon-color-trans "
                    ></img>
                    <p className="font-semibold pl-2">Add to Home Screen</p>
                  </div>
                )}

              <a
                href={`${hostName}/client/${id}`}
                rel="noreferrer"
                target="_blank"
                className=""
                onClick={() => {
                  setMenu(false);
                }}
              >
                {generalSettings?.Client_panel_icon && (
                  <div className="flex flex-row items-center cursor-pointer  mb-2">
                    <img
                      alt="Setting Icon "
                      src={generalSettings.Client_panel_icon}
                      height={30}
                      width={30}
                      className="social-icon-color-trans"
                    />
                    <p className="font-semibold pl-2">Client Panel</p>
                  </div>
                )}
              </a>

              {generalSettings?.zaags_logo && (
                <div className="flex flex-row items-center cursor-pointer">
                  <img
                    src={generalSettings?.icon_create_app}
                    alt="share"
                    className="social-icon-color-trans mb-2 cursor-pointer"
                    height={30}
                    width={30}
                    onClick={() =>
                      window.open("https://zaags.com/business-apps")
                    }
                  ></img>
                  <p
                    className="font-semibold pl-2"
                    onClick={() =>
                      window.open("https://zaags.com/business-apps")
                    }
                  >
                    create your app in minutes
                  </p>
                </div>
              )}
              {clientData.icon_favourites_active === "1" &&
                generalSettings["icon_Add_to _favourites"] && (
                  <a href={`https://my.zaags.com/${clientData.app_id}`}>
                    <div className="flex flex-row items-center cursor-pointer">
                      <img
                        src={generalSettings?.["icon_Add_to _favourites"]}
                        height={30}
                        width={30}
                        alt=""
                        className="social-icon-color-trans mb-2 "
                      ></img>
                      <p className="font-semibold pl-2">Add to Favourites</p>
                    </div>
                  </a>
                )}
              {generalSettings?.Client_panel_icon && (
                <>
                  <div className="flex flex-col items-center cursor-pointer">
                    {/* {clientData.is_bnb === "1" && (
                      <a
                        className="flex cursor-pointer mt-2"
                        href={`https://zaags.com/wp-content/uploads/owner/${app_name}_instructions.pdf`}
                      >
                        <p className="font-semibold flex justify-center items-center">
                          Instructions PDF
                        </p>
                      </a>
                    )} */}
                    <p
                      className="font-semibold flex justify-center items-center"
                      onClick={() => (setPdfPresentPopup(true), setMenu(false))}
                    >
                      Instructions PDF
                    </p>
                    <p
                      className="font-semibold mb-4"
                      onClick={() => {
                        setQrCode(true);
                        setMenu(false);
                      }}
                    >
                      QR code for this App
                    </p>
                    <img
                      height={100}
                      width={100}
                      src={clientData.QR_Code_image_URL}
                      alt="qr-code"
                      className=" mb-2"
                      onClick={() => {
                        setQrCode(true);
                        setMenu(false);
                      }}
                    />
                    <a href="https://www.zaags.com/">
                      <p className="font-semibold mb-4">Powered by Zaags</p>
                    </a>
                  </div>

                  <button>
                    {clientData.printshop_pdf_only_front !== "" &&
                      clientData.printshop_pdf_only_front !== null && (
                        // <a
                        //   href={clientData.printshop_pdf_only_front}
                        //   download="Rishabh's Resume"
                        //   target="_blank" rel="noreferrer"
                        // >
                        <img
                          src="https://zaags.com/wp-content/uploads/2023/03/print.png"
                          alt="print"
                          height={30}
                          width={30}
                          onClick={() => onButtonClick()}
                          className="py-2"
                        />
                        // </a>
                      )}
                  </button>
                </>
              )}
            </div>
          </div>
        </>
      )}

      {pdfPresentPopup === true && (
        <div className="fixed inset-0 z-[999999] overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-[90%] p-4 mx-auto bg-white rounded-md shadow-lg">
              <div className="flex flex-row justify-between items-center mb-2 md:mb-4">
                <button>
                  {generalSettings.back_button_image && (
                    <img
                      src={generalSettings.back_button_image}
                      height={60}
                      width={60}
                      alt="back"
                      className="left-0"
                      onClick={() => setPdfPresentPopup(false)}
                    />
                  )}
                </button>
                <h1 className="flex flex-row justify-center w-full font-bold text-md md:text-3xl">
                  Instruction PDF
                </h1>
                {clientData.is_bnb === "1" && (
                  <a
                    className="flex cursor-pointer mt-2"
                    href={`https://zaags.com/wp-content/uploads/owner/${app_name}_instructions.pdf`}
                  >
                    <img
                      src="https://zaags.com/wp-content/uploads/2023/03/print.png"
                      alt="print"
                      height={30}
                      width={30}
                      className="py-2"
                    />
                  </a>
                )}
              </div>

              <div>
                <ul>
                  {Object.entries(formData).map(([category, services]) => {
                    if (typeof services === "object" && services !== null) {
                      return (
                        <div key={category} style={{ marginBottom: "20px" }}>
                          <h1>
                            <strong>{category}</strong>
                          </h1>
                          <ul>
                            {Object.entries(services)
                              .filter(([, serviceValue]) => serviceValue) // Filter out empty values
                              .map(([serviceKey, serviceValue]) => (
                                <li key={serviceKey}>
                                  <h3 className="font-semibold block md:inline">
                                    {serviceKey}:
                                  </h3>{" "}
                                  {serviceValue}
                                </li>
                              ))}
                          </ul>
                        </div>
                      );
                    }
                    return null; // Handle case when services is not an object
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}

      {photos.length === 0 && (
        <div className="middle2">
          <p className="text-4xl font-bold flex flex-row justify-center">
            No images to show
          </p>
        </div>
      )}
      {slider && (
        <div className="fixed inset-0 z-[999999] overflow-y-auto ">
          <div
            className="fixed inset-0 w-full h-full "
            onClick={() => setSlider(false)}
          ></div>
          <div className="flex items-center  px-4 py-8">
            <div className="relative theme-bg-image align-middle w-full p-4 mx-auto rounded-md border-2 border-solid border-white">
              <div className="relative z-10 flex flex-col lg:flex-row items-start">
                <button
                  className="cursor-pointer"
                  onClick={() => {
                    setSlider(false);
                  }}
                >
                  {generalSettings.back_button_image && (
                    <img
                      src={generalSettings.back_button_image}
                      height={60}
                      width={60}
                      alt="back"
                    />
                  )}
                </button>
                {imageURL && imageURL.includes(".mp4") ? (
                  <>
                    <video
                      width="640"
                      height="480"
                      autoPlay
                      muted
                      loop
                      className="mx-auto"
                    >
                      <source src={imageURL} type="video/mp4" />
                    </video>
                    <script>document.getElementById('vid').play();</script>
                  </>
                ) : (
                  <img src={imageURL} alt="share" className="mx-auto"></img>
                )}
                {/* <img src={imageURL} alt="share" className="mx-auto"></img> */}
              </div>
            </div>
          </div>
        </div>
      )}
      {photos && (
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {photos
            .filter((photo: { [key: string]: string }) =>
              selectedOption === "" 
            ? true 
            : photo[filterKey] === selectedOption
            )
            .map((i: any, index: any) => {
              return (
                <div key={index} className="containers my-masonry-item">
                  <div
                    className="relative group pb-4"
                    id={`screenshot-target${index}`}
                  >
                    {i && (
                      <>
                        {i.image && (
                          <div className="flex flex-col items-center relative">
                            <div className="relative">
                              {/* {i.coordinates &&
                              (i.coordinates[0] ||
                                i.coordinates[1] ||
                                i.coordinates[2] ||
                                i.coordinates[3] !== "") && (
                                <img
                                  id="logo"
                                  src={
                                    clientData.logo_photo
                                      ? clientData.logo_photo
                                      : clientData.logo_url
                                  }
                                  alt="Logo"
                                  style={{
                                    position: "absolute",
                                    top: `${i.coordinates[0]}${
                                      i.coordinates[0] !== "50%" ? "px" : ""
                                    }`,
                                    left: `${i.coordinates[1]}${
                                      i.coordinates[1] !== "35%" ? "px" : ""
                                    }`,
                                    bottom: `${i.coordinates[2]}px`,
                                    right: `${i.coordinates[3]}px`,
                                    height: "50px",
                                  }}
                                />
                              )} */}

                              {i.image && i.image.includes(".mp4") ? (
                                <>
                                  <video
                                    width="640"
                                    height="480"
                                    autoPlay
                                    muted
                                    loop
                                  >
                                    <source src={i.image} type="video/mp4" />
                                  </video>
                                  <script>
                                    document.getElementById('vid').play();
                                  </script>
                                </>
                              ) : (
                                <img src={i.image} alt="share"></img>
                              )}
                              {/* <img src={i.image} alt="share"></img> */}
                              {/* <img
                            src={i.image}
                            alt="camera capture"
                            className="mx-auto url border border-solid border-white"
                          /> */}
                            </div>
                            <div className="absolute opacity-0 group-hover:opacity-100 bg-white/40 transition-all duration-300 top-0 left-0 w-full h-full flex items-center justify-center gap-5">
                              <RWebShare
                                data={{
                                  text: "Share",
                                  url: `${i.image}`,
                                }}
                              >
                                <button>
                                  {generalSettings.icon_share_app && (
                                    <>
                                      {/* <Tooltip id="my-tooltip-share" /> */}
                                      <div
                                        data-tooltip-id="my-tooltip-share"
                                        data-tooltip-html="Share"
                                      >
                                        <img
                                          className="cursor-pointer h-8 w-8"
                                          src="https://img.icons8.com/fluency-systems-regular/48/share.png"
                                          alt="share"
                                          // onClick={() => shareLink()}
                                        />
                                      </div>
                                    </>
                                  )}
                                </button>
                              </RWebShare>
                              {/* <Tooltip id="my-tooltip-slider" /> */}
                              <img
                                src="https://img.icons8.com/windows/32/download--v1.png"
                                alt="download--v1"
                                className="cursor-pointer h-8 w-8"
                                data-id={`screenshot-target${index}`}
                                onClick={(e) => {
                                  // setDownload(true);
                                  screenshot(e);
                                }}
                              />
                              <div
                                data-tooltip-id="my-tooltip-read"
                                data-tooltip-html="View"
                              >
                                <img
                                  src="https://img.icons8.com/material-outlined/24/visible--v1.png"
                                  alt="visible--v1"
                                  className="cursor-pointer h-8 w-8"
                                  onClick={() => {
                                    setSlider(true);
                                    setImageURL(i.image);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    <div className="flex flex-col items-center">
                      <p className="break-all">{i.text}</p>
                      <p className="break-all">{i.date}</p>
                      <p className="break-all">
                        {labels === "emails"
                          ? i.email
                          : labels === "display-anonymous"
                          ? "Anonymous"
                          : i.username
                          }
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
        </Masonry>
      )}
    </>
  );
};

export default Gallery;

import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const API_TOKEN = process.env.REACT_APP_API_TOKEN;

axios.defaults.headers.common["Authorization"] = `Bearer ${API_TOKEN}`;

const getAppGeneralSettings = async (): Promise<any> => {
  const response = await axios.get(
    `${API_URL}/settings/get-app-general-settings`
  );
  return response.data;
};

const getClientData = async (id: any): Promise<any> => {
  const response = await axios.get(`${API_URL}/newclients/${id}`);
  return response.data;
};

const updateClientViewCount = async (id: any): Promise<any> => {
  const response = await axios.post(
    `${API_URL}/clients/update-app-view-count`,
    { appid: id }
  );
  return response.data;
};

const updateWifiData = async (wifiDetail: any): Promise<any> => {
  const response = await axios.post(
    `${API_URL}/clients/wifi-details-update`,
    wifiDetail
  );
  return response.data;
};

const adsData = async (id: any) => {
  const response = await axios.get(`${API_URL}/fetch/client_ads/${id}`);
  return response.data;
};

const welcomePopup = async (welcomeData: any) => {
  var data = new FormData();

  for (var key in welcomeData) {
    data.append(key, welcomeData[key]);
  }
  return axios
    .post(`${API_URL}/user/savedata/additional-settings`, data)
    .then((response: any) => {
      return response.data;
    });
};

const sendPhotoData = (data2: any) => {

  let isBase64 = data2?.client_photo;
  var data = new FormData();
  data.append("client_id", data2?.client_id);
  data.append("date", data2.date);
  data.append("text", data2?.text);
  data.append("email", data2?.email);
  data.append("username", data2?.username);
  data.append("coordinates", data2?.coordinates);
  data.append("bg_logo", data2?.bg_logo);

  if (typeof data2?.client_photo === "object") {
    for (const key of Object.keys(data2?.client_photo)) {
      data.append("client_photo[]", data2?.client_photo[key]);
    }
  }
  if (typeof data2?.client_photo === "string") {
    data.append("client_photo", data2?.client_photo);
  }

  return axios
    .post(
      `${API_URL}/clients/photocapture`,
      typeof isBase64 === "string" ? data2 : data
    )
    .then((response: any) => {
      return response.data;
    });
};

const getShareData = async (id: any) => {
  return axios
    .get(`${API_URL}/fetch/share_icon_design/${id}`)
    .then((response: any) => {
      return response.data;
    });
};

const getPhotos = async (id: any) => {
  const response = await axios.get(
    `https://zaags.com/wp-json/fetch/client_photos/${id}`
  );
  return response.data;
};

const getLockData = async (id: any) => {
  return axios
    .get(`${API_URL}/fetch/user_lock_icons/${id}`)
    .then((response: any) => {
      return response.data;
    });
};

const getWelcomePopupData = async (data: any): Promise<any> => {
  const response = await axios.post(`${API_URL}/clients/popup_template/`, data);
  return response.data;
};

const updateStatus = async (id: any) => {
  return axios
    .post(`${API_URL}/clients/update_status?Client_Id=${id}`, { Client_Id: id })
    .then((response: any) => {
      return response.data;
    });
};

const checkLogin = async (loginData: any): Promise<any> => {
  const response = await axios.post(
    `${API_URL}/my_user_login/check`,
    loginData
  );
  return response.data;
};

const vcardLogin = async (vcardLoginData: any): Promise<any> => {

  const response = await axios.post(`${API_URL}/Vcard/email`, vcardLoginData);
  return response.data;
};

const loginDetail = async (data: any): Promise<any> => {

  const response = await axios.post(`${API_URL}/fetch/social/user-social-details`, data);
  return response.data;
};

const checkExist = async (tableData: any): Promise<any> => {
  var data = new FormData();

  for (var key in tableData) {
    data.append(key, tableData[key]);
  }
  const response = await axios.post(
    `${API_URL}/my_user_login/email_exists`,
    data
  );
  return response.data;
};

const quickSetup = async (data: any): Promise<any> => {
  // var data = new FormData();

  // for (var key in tableData) {
  //   data.append(key, tableData[key]);
  // }
  const response = await axios.post(
    `${API_URL}/user/quicksetup`,
    data
  );
  return response.data;
};


const verifyExist = async (tableData: any): Promise<any> => {
  var data = new FormData();

  for (var key in tableData) {
    data.append(key, tableData[key]);
  }
  const response = await axios.post(
    `${API_URL}/my_user_login/verify_email`,
    data
  );
  return response.data;
};

const loginRecordData = async (lockData: any) => {
  var data = new FormData();

  for (var key in lockData) {
    data.append(key, lockData[key]);
  }
  return axios
    .post(`${API_URL}/app/update_login_count`, data)
    .then((response: any) => {
      return response.data;
    });
};

const userData = async (tableData: any): Promise<any> => {
  var data = new FormData();

  for (var key in tableData) {
    data.append(key, tableData[key]);
  }
  const response = await axios.post(`${API_URL}/update/newsletter`, data);
  return response.data;
};

const enableWIFI = async (wifiData: any) => {
  var data = new FormData();

  for (var key in wifiData) {
    data.append(key, wifiData[key]);
  }

  return axios
    .post(`${API_URL}/clients/wifi-enable-option-update`, data)
    .then((response: any) => {
      return response.data;
    });
};

const newRegister = async (registerData: any) => {
  var data = new FormData();

  for (var key in registerData) {
    data.append(key, registerData[key]);
  }

  return axios
    .post(`${API_URL}/my_user_login/register`, data)
    .then((response: any) => {
      return response.data;
    });
};

const getInstructions = async (id: any) => {
  return axios
    .get(`${API_URL}/clients/get-app-instructions?app_id=${id}`)
    .then((response: any) => {
      return response.data;
    });
};

const getFrameDetail = async (url: any): Promise<any> => {
  var data = new FormData();
  data.append("url", url);

  return axios
    .post(`${API_URL}/fetch/header_option_origin/`, data)
    .then((response: any) => {
      return response.data;
    });
};

export {
  getAppGeneralSettings,
  getClientData,
  updateClientViewCount,
  updateWifiData,
  adsData,
  sendPhotoData,
  getShareData,
  getLockData,
  updateStatus,
  checkLogin,
  loginRecordData,
  getFrameDetail,
  getWelcomePopupData,
  userData,
  enableWIFI,
  newRegister,
  checkExist,
  verifyExist,
  vcardLogin,
  quickSetup,
  loginDetail,
  getPhotos,
  getInstructions,
  welcomePopup
};
